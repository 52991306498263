.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .header {
    margin-bottom: 30px;
    margin-top: 50px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 20px;
}

.buttonsContainer button {
  all: unset;
  opacity: 0.7;
  cursor: pointer;
}

@media (hover: hover) {
  .buttonsContainer button:hover {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
