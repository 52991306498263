.root {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.root[data-visible="true"] {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
  .content {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .content {
    gap: 70px;
  }
}

.description p {
  font-size: 18px;
  line-height: 30px;
}

.description p:first-child {
  margin-top: 0;
}

@media (min-width: 1024px) {
  .description p {
    font-size: 20px;
    line-height: 28px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 20px;
}

.buttonsContainer button {
  all: unset;
  opacity: 0.7;
  cursor: pointer;
}

@media (hover: hover) {
  .buttonsContainer button:hover {
    opacity: 1;
    transition: opacity 0.5s;
  }
}

.answers {
  display: flex;
  flex-direction: column;
  gap: 15px;

  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .answers {
    margin-bottom: 0;
    margin-top: auto;
  }
}

@media (min-width: 1024px) {
  .imgContainer img {
    height: 100%;
  }
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: black;
}
