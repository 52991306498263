.container svg {
  width: auto;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
}

.container[data-screen="loading-screen"] {
  width: 128px;
  height: 135px;
}

.container[data-screen="main-menu-screen"],
.container[data-screen="music-options-screen"] {
  width: 100px;
  height: 105px;
}

.container[data-screen="game-screen"] {
  width: 60px;
  height: 63px;
}

.container[data-screen="titles-screen"] {
  width: 100px;
  height: 105px;
}

@media (min-width: 1024px) {
  .container[data-screen="main-menu-screen"],
  .container[data-screen="music-options-screen"] {
    width: 128px;
    height: 135px;
  }

  .container[data-screen="game-screen"] {
    width: 93px;
    height: 98px;
  }

  .container[data-screen="titles-screen"] {
    width: 128px;
    height: 135px;
  }
}
