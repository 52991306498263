.root {
  all: unset;
  box-sizing: border-box;
  display: block;
  width: 100%;

  font-size: 20px;
  line-height: 22px;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.78);
  border: 1px solid #414141;

  cursor: pointer;
}

.root[data-align="left"] {
  text-align: left;
}

.root[data-align="center"] {
  text-align: center;
}

.root[data-size="big"] {
  padding: 20px 15px;
}

.root[data-size="small"] {
  text-align: center;
  width: 206px;
  padding: 10px 15px;
}

@media (hover: hover) {
  .root:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
    cursor: pointer;
  }
}
