.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
  margin-top: 30px;
}
