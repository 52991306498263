.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "logo" "content";
}

@media (min-width: 1024px) {
  .root {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". logo" "content content";
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;

  grid-area: content;
}

@media (min-width: 1024px) {
  .content {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
}

.logoArea {
  grid-area: logo;
  justify-self: center;
}

.textContent {
  display: flex;
  flex-direction: column;
}

.audioPlayerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  min-height: 65px;
}

.description {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.authorsList {
  margin: 0;
  padding: 0;
  list-style: none;

  font-size: 16px;
  line-height: 35px;
  opacity: 0.8;
}

.authorsList li {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

@media (min-width: 1024px) {
  .authorsList li {
    grid-template-columns: 1fr 2fr;
  }
}

.authorsList li .name {
  text-align: right;
}

.releaseInfo {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.socialsContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 50px 0;
}

.socialsContainer a {
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
  cursor: pointer;
}

@media (hover: hover) {
  .socialsContainer a:hover {
    opacity: 1;
    transition: opacity 0.5s;
  }
}

.restartButtonContainer {
  margin: 20px 0px;
}
