.container {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content[data-animated="true"] {
  animation: flip-vertical-right 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both infinite alternate;
}

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}
