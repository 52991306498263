body {
  margin: 0;

  background-color: #1e1e1e;
  color: #ffffff;

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}
