.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainScreenImgContainer {
  width: 310px;
  height: 310px;
}

@media (min-width: 1024px) {
  .mainScreenImgContainer {
    width: 465px;
    height: 465px;
  }
}

.gameName,
.gameSlogan {
  margin: 0;

  font-size: 24px;
  line-height: 44px;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gameName {
  margin-bottom: 15px;
  text-align: center;
}

@media (min-width: 1024px) {
  .gameName {
    margin-bottom: 25px;
  }
}

.gameSlogan {
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socialsContainer {
  margin-top: 25px;
  margin-bottom: 15px;
}

.socialsContainer a {
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
  cursor: pointer;
}

.socialsContainer a[data-type="chaika-records"] {
  line-height: 44px;
  font-size: 20px;

  text-align: center;
  font-variant: small-caps;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.socialsContainer .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

@media (hover: hover) {
  .socialsContainer a:hover {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
